import { CircularProgress, InputAdornment } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import {errorBigIcon, iconNotifySuccess, toastSuccess} from '../../../assets/icons/icons';
import ValidationErrorBlock from '../../../components/Blocks/ValidationErrorBlock/ValidationErrorBlock';
import IsVisibleButton from '../../../components/Buttons/IsVisibleButton/IsVisibleButton';
import { Button, FormControl, TextField } from '../../../components/StyledComponents';
import {MAX_INPUT_VALUE_LENGTH, PASSWORD_REGEX} from '../../../models/consts';
import { IError, IPasswordsVisiblity, IRecoverPasswordForm, IRecoverPasswordProps } from '../../../models/inner-models';
import { IRecoverPasswordConfirm } from '../../../models/models';
import { confirmPasswordRecover, recoverPasswordCognito } from '../../../store/actions';
import { useAppDispatch } from '../../../store/hooks';
import { decoratePopUpMessage } from '../../../utils/popUpTextDecorator';
import {zxcvbn, zxcvbnOptions} from "@zxcvbn-ts/core";
import * as zxcvbnEnPackage from "@zxcvbn-ts/language-en";
import * as zxcvbnCommonPackage from "@zxcvbn-ts/language-common";

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      marginTop: '4px',
      width: '100%',
      height: '42px',
      '& input': {
        height: '42px',
        boxSizing: 'border-box'
      }
    },
    fullWidthButton: {
      width: '100%',
      marginTop: '16px',
      padding: '0',
      fontSize: '14px',
      fontWeight: '600',
      height: '44px',
      boxSizing: 'border-box',
      border: '1px solid #6D4CF0'
    },
    resend: {
      cursor: 'pointer',
      fontSize: '14px',
      textDecoration: 'underline',
      textAlign: 'right'
    }
  }),
);

const RecoverPasswordConfirmation: FunctionComponent<IRecoverPasswordProps> = ({ email }: IRecoverPasswordProps) => {
    const dispatch = useAppDispatch();
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const [isProcessing, setProcessing] = useState<boolean>(false);

    const { register, formState: { errors, isValid }, handleSubmit, control } = useForm<IRecoverPasswordForm>({
      mode: 'onChange',
    });
    const [notMatchError, setNotMatchError] = useState<boolean>(true);
    const mainPass = useWatch({ control, name: 'password' });

    const [visible, setPasswordVisiblity] = useState<IPasswordsVisiblity>({
      password: false
    });

    const options = {
      translations: zxcvbnEnPackage.translations,
      graphs: zxcvbnCommonPackage.adjacencyGraphs,
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEnPackage.dictionary,
      },
    }
    zxcvbnOptions.setOptions(options)

    const handleClickShowPassword = (field: keyof IPasswordsVisiblity) => () => {
      setPasswordVisiblity({ ...visible, [field]: !visible[field] });
    };

    useEffect(() => {
      if (PASSWORD_REGEX.test(mainPass) && !(!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "dictionary")?.length ||
        (!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "repeat")?.length && zxcvbn(mainPass)?.score === 0))) {
        setNotMatchError(false);
      } else {
        setNotMatchError(true);
      }
    }, [mainPass]);

    const onResend = async () => {
      try {
        setProcessing(true);
        await dispatch(recoverPasswordCognito({ email })).unwrap();
        Swal.fire({
          title: translate('notifications.titles.success'),
          text: 'A verification code has been sent to your email.',
          imageUrl: iconNotifySuccess,
          confirmButtonText: 'OK',
        });
      } catch (err) {
        const error = err as IError;

        console.log(err);

        return Swal.fire({
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.error as string),
          imageUrl: errorBigIcon,
          confirmButtonText: translate('notifications.choices.close'),
        });
      } finally {
        setProcessing(false);
      }
    };

    const onSubmit: SubmitHandler<IRecoverPasswordForm> = async (data) => {
      if (notMatchError) {
        return;
      }

      const recoverPassword: IRecoverPasswordConfirm = {
        email,
        confirmation_code: data.code,
        new_password: data.password,
      };

      try {
        setProcessing(true);
        await dispatch(confirmPasswordRecover(recoverPassword)).unwrap();
        await Swal.fire({
          title: translate('notifications.titles.success'),
          text: 'Your password was changed',
          toast: true,
          position: 'top-end',
          timerProgressBar: true,
          showConfirmButton:false,
          showCloseButton: true,
          imageUrl: toastSuccess,
          timer: 3000,
        });

        navigate('/auth/login');

      } catch (err) {
        const error = err as IError;

        console.log(error);
        Swal.fire({
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.error as string),
          imageUrl: errorBigIcon,
          confirmButtonText: translate('notifications.choices.close'),
        });
      } finally {
        setProcessing(false);
      }
    };


    return (
      <div id="confirmed-recover-password">
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row">
            <div className="label-block">{translate('forms.recover_password.verification_code')}</div>
            <FormControl fullWidth={true}>
              <TextField
                className={classes.textField}
                placeholder={translate('forms.recover_password.verification_code')}
                required
                autoFocus
                autoComplete="off"
                id="verifiction-code"
                {...register('code', {
                  required: translate('forms.common.required') as string,
                })}
                error={!!errors.code}
              />
              {errors.code &&
                <ValidationErrorBlock errorMessage={errors.code.message as string} />
              }
            </FormControl>
          </div>
          <div className="form-row">
            <div className="label-block">{translate('forms.recover_password.new_pass')}</div>
            <FormControl fullWidth={true}>
              <TextField
                className={classes.textField}
                placeholder={translate('forms.common.password')}
                required
                id="password"
                type={visible.password ? 'text' : 'password'}
                {...register('password', {
                  required: translate('forms.common.required') as string,
                  // pattern: {
                  //   value: PASSWORD_REGEX,
                  //   message: translate('forms.common.invalid_password'),
                  // },
                  maxLength: {
                    value: MAX_INPUT_VALUE_LENGTH,
                    message: translate('forms.common.max_length50'),
                  },
                })}
                error={!!errors.password}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IsVisibleButton
                        isPrimary
                        onClick={handleClickShowPassword('password')}
                        isNowVisible={visible.password}
                      />
                    </InputAdornment>,
                }}
              />
              {errors.password &&
                <ValidationErrorBlock errorMessage={errors.password.message as string} />
              }
              {(!!mainPass?.length) && (
                <ul className={mainPass?.length === 0 ? 'auth-password-help validation' : 'auth-password-help'}>
                  <li className={mainPass?.length > 9 ? 'success' : 'error'}>
                    10 characters minimum
                  </li>
                  <li className={
                    !!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "dictionary")?.length ||
                    (!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "repeat")?.length && zxcvbn(mainPass)?.score === 0) ? 'error' : 'success'
                  }>
                    No dictionary words, repetitions and commonly used passwords
                  </li>
                  <li className={/^(.*[0-9].*)$/.test(mainPass) ? 'success' : 'error'}>
                    One number
                  </li>
                  <li className={/^(?=.*[a-z])(?=.*[A-Z]).*$/.test(mainPass) ? 'success' : 'error'}>
                    At least one uppercase and one lowercase
                  </li>
                  <li className={/^(?=.*[@$!%*^"])[a-zA-Z0-9@$!%*^"]+$/.test(mainPass) ? 'success' : 'error'}>
                    One special character. Use only: $ ! % * ^ &quot; @
                  </li>
                  {(!PASSWORD_REGEX.test(mainPass) || (!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "dictionary")?.length ||
                    (!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "repeat")?.length && zxcvbn(mainPass)?.score === 0))) && (
                    <li className={'error'}>
                      {translate('forms.common.invalid_password')}
                    </li>
                  )}
                </ul>
              )}
            </FormControl>
          </div>
          <div className="form-row">
            <div className={classes.resend} onClick={onResend}>
              Resend code
            </div>
          </div>
          <div className="action-row">
            <Button className="primary-button w-100" type="submit" disabled={!isValid || notMatchError || isProcessing}>
              {isProcessing &&
                <div className="small-spinner-wrapper">
                  <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '14px' }} />
                </div>
              }
              {isProcessing
                ? translate('notifications.choices.processing')
                : translate('forms.recover_password.title')}
            </Button>
          </div>
        </form>
      </div>
    );

  }
;

export default RecoverPasswordConfirmation;
